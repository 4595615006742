@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
.footer {
  position: fixed;
  bottom: 0px;
  right: 10px;
}

.login-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.login-right,
.login-left {
  display: flex;
  flex-direction: column;
}

.login-right,
.login-left {
  padding: 0px;
  height: 100vh;
}

.login-right {
  background-image: url("../images/shared/bground.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  border-left: 3px solid #ffbc49;
  opacity: 0.9;
  width: 70vw;
}

.login-left {
  width: 30vw;
}
.login-left .login-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-grow: 1;
  padding: 70px;
}
.login-left .login-title {
  text-align: left;
  color: #6d6d6d;
  font-size: 28px;
  font-weight: bold;
}
.login-left .login-subtitle {
  text-align: left;
  color: #6d6d6d;
  font-size: 18;
}
.login-left .login-label {
  color: #6d6d6d;
  font-size: 13px;
  font-weight: bold;
}

.main-sidebar {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  white-space: nowrap;
}

.main-navbar {
  top: 0;
  left: 0;
}

.main-sidebar {
  left: 0;
  transition: width 0.3s ease-in-out;
}

.right-sidebar {
  right: 0;
  transition: width 0.3s ease-in-out;
}

.main-panel,
.main-navbar {
  position: relative;
  width: calc(100% - 0px);
  float: right;
  transition: width 0.3s ease-in-out;
}

.main-panel-expand,
.main-navbar-expand {
  width: 100%;
}

.main-sidebar-expand {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.main-content {
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

/** Keyframes */
@keyframes slide-from-right {
  100% {
    right: 0;
  }
}
@keyframes slide-to-right {
  100% {
    right: -400px;
  }
}
@keyframes slide-to-left {
  100% {
    width: calc(100% - 600px);
  }
}
@keyframes slide-from-left-to-right {
  100% {
    width: 100%;
  }
}
@keyframes slide-from-right-to-right {
  100% {
    width: 100%;
  }
}
.navbar-wrapper {
  background-color: #118acb;
  display: flex;
  height: 50px;
  width: 100%;
}
.navbar-wrapper .navbar-content {
  width: 95%;
}
.navbar-wrapper .navbar-profile .logout-btn {
  color: #ffffff;
  padding-top: 6px;
  padding-left: 30px;
  position: absolute;
  right: 10px;
}
.navbar-wrapper .navbar-profile .logout-btn .mat-menu,
.navbar-wrapper .navbar-profile .logout-btn .mat-icon {
  outline: none !important;
}
.navbar-wrapper .navbar-profile .logout-btn .mat-icon {
  font-size: 32px;
}
.navbar-wrapper .navbar-profile .hamburger-btn {
  color: #ffffff;
  padding-top: 6px;
  position: absolute;
  left: -5px;
}
.navbar-wrapper .navbar-profile .hamburger-btn .mat-menu,
.navbar-wrapper .navbar-profile .hamburger-btn .mat-icon {
  outline: none !important;
}
.navbar-wrapper .navbar-profile .hamburger-btn .mat-icon {
  font-size: 32px;
}

.wrapper .sidebar {
  background: #118acb;
  position: fixed;
  top: 0;
  left: 0;
  width: 205px;
  height: 100%;
  padding: 10px 0;
  padding-bottom: 180px;
  transition: all 0.5s ease;
  overflow: hidden;
}

.wrapper .sidebar .profile {
  margin-bottom: 30px;
  text-align: center;
}

.wrapper .sidebar .profile img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.wrapper .sidebar .profile h3 {
  color: #ffffff;
  margin: 10px 0 5px;
}

.wrapper .sidebar .profile p {
  color: #cef0fd;
  font-size: 14px;
}

.wrapper .sidebar ul li a {
  display: block;
  padding: 13px 30px;
  color: #f1eded;
  font-size: 16px;
  position: relative;
}

.wrapper .sidebar ul li a .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}

.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active {
  color: #118acb;
  background: white;
  border-right: 2px solid #118acb;
  border-radius: 6px;
}

.wrapper .sidebar ul li a:hover .icon,
.wrapper .sidebar ul li a.active .icon {
  color: #0c7db1;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before {
  display: block;
}

.wrapper .section {
  width: calc(100% - 0px);
  margin-left: 204px;
  transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
  background: #118acb;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.wrapper .section .top_navbar .hamburger a {
  font-size: 28px;
  color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover {
  color: #a2ecff;
}

.wrapper .section .container {
  margin: 30px;
  background: #fff;
  padding: 50px;
  line-height: 28px;
}

body.active .wrapper .sidebar {
  left: -225px;
}

body.active .wrapper .section {
  margin-left: 0;
  width: 100%;
}

.drawer {
  position: fixed;
  height: 100vh;
  min-width: 25%;
  border-right: 1px solid #ccc;
}

.drawer .header {
  position: absolute;
  right: 0;
  justify-content: space-between;
}

.drawer .contents {
  padding: 16px;
}

.main {
  background-color: #fff;
}

.main.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #ccc;
}

.table-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 4px 8px 18px #118acb;
  margin-top: 50px;
}
.table-wrapper .table-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-wrapper .table-header .header {
  font-size: 26px;
  font-weight: bold;
  color: #6d6d6d;
}
.table-wrapper .table-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table-wrapper .table-content table,
.table-wrapper .table-content th,
.table-wrapper .table-content td,
.table-wrapper .table-content tr {
  text-align: left !important;
  padding: 0px;
  margin: 0px;
}
.table-wrapper .table-content table,
.table-wrapper .table-content th,
.table-wrapper .table-content td {
  border: none;
}
.table-wrapper .table-content td {
  background: #ffffff;
}
.table-wrapper .table-content td button {
  float: right;
  border: none;
  background: none;
  color: #ffa200;
  font-size: 20px;
  cursor: pointer;
}
.table-wrapper .table-content td button:hover {
  color: #2d2d2d;
}
.table-wrapper .table-content th {
  background-color: whitesmoke;
  color: #118acb;
  font-size: 14px;
  height: 30px !important;
  padding: 5px;
  max-width: 100px !important;
}
.table-wrapper .table-content tr {
  border-bottom: 1px solid #f1f1f1;
  font-size: 12px;
  height: 35px !important;
  padding: 5px;
}
.table-wrapper .column-filter {
  background: #ffffff !important;
  position: relative;
  margin: 10px 0;
}
.table-wrapper .column-filter input {
  height: 25px;
  padding: 5px;
  outline: 0;
  border: 1px solid #6d6d6d;
  border-radius: 3px;
  transition: 0.1s all;
}
.table-wrapper .column-filter input::placeholder {
  font-size: 14px;
  font-style: italic;
}
.table-wrapper .column-filter input:focus {
  transition: 0.1s ease-out;
  border-bottom: 2px solid #2d2d2d;
  padding: 5px 5px 3px 5px;
}
.table-wrapper .mat-icon {
  color: lightgray;
  margin: 3px 5px;
}
.table-wrapper .mat-icon.red:hover {
  color: #e11a22 !important;
}
.table-wrapper .mat-icon.orange:hover {
  color: #ffa200 !important;
}

.table-wrapper-show-form {
  animation: slide-to-left 0.5s forwards;
}

.table-wrapper-close-form {
  animation: slide-from-left-to-right 0.5s forwards;
}

.show-form,
.hide-form {
  height: 100%;
  min-height: 600px;
  position: fixed;
  right: 0px;
  top: 50px;
  width: 600px;
}

.show-form {
  right: -600px;
  animation: slide-from-right 0.5s forwards;
}

.hide-form {
  right: 0px;
  animation: slide-to-right 0.5s forwards;
  display: none;
}

.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.form-content {
  padding: 20px;
}

input .mat-input-element {
  width: 250px !important;
}

.form-header {
  font-size: 17px;
  font-weight: bold;
}

.form-close-btn {
  border: none;
  position: absolute !important;
  top: 10px;
  right: 10px;
  background-color: transparent !important;
  cursor: pointer;
}
.form-close-btn > .mat-icon {
  color: #2d2d2d;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none !important;
  z-index: 1;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: absolute;
  top: 49%;
  left: 49%;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(172, 12, 12, 0.2);
  border-right: 1.1em solid rgba(172, 12, 12, 0.2);
  border-bottom: 1.1em solid rgba(172, 12, 12, 0.2);
  border-left: 1.1em solid #e11a22;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
  z-index: 10;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
body {
  overflow-x: hidden;
}

.container {
  max-width: 100% !important;
}

/** BUTTONS */
.btn-success {
  background-color: #0180e0 !important;
}

.btn-warning {
  background-color: #ffa200 !important;
}
.btn-warning:hover {
  background-color: #ffbc49 !important;
}

.btn-secondary {
  background-color: #30962c !important;
  color: #ffffff !important;
}
.btn-secondary:hover {
  background-color: #277724 !important;
}

/** MATERIAL */
.mat-form-field {
  display: block !important;
}

.mat-form-field-infix {
  width: 420px !important;
}
.mat-form-field-infix input {
  padding: 0px 5px !important;
}
.mat-form-field-infix input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
.mat-form-field-infix input[type=password],
.mat-form-field-infix input[type=text],
.mat-form-field-infix input[type=number],
.mat-form-field-infix textarea {
  border-bottom: none !important;
  box-shadow: none !important;
}
.mat-form-field-infix textarea {
  min-height: 120px;
  padding: 10px !important;
  scrollbar-width: 0px !important;
  resize: none !important;
}