.navbar-wrapper {
  background-color: $colorBlueTertiary;
  display: flex;
  height: 50px;
  width: 100%;

  .navbar-content {
    width: 95%;
  }

  .navbar-profile {
    .logout-btn {
      color: $colorWhitePrimary;
      padding-top: 6px;
      padding-left: 30px;
      position: absolute;
      right: 10px;

      .mat-menu,
      .mat-icon {
        outline: none !important;
      }

      .mat-icon {
        font-size: 32px;
      }
    }

    .room-registry-dropdown {
      color: $colorWhitePrimary;
      padding-top: 6px;
      position: absolute;
      left: -5px;
      display: flex;
      align-items: center;
    }

    .room-registry-dropdown .dropdown-content {
      display: flex;
      align-items: center;
      /* Centers the text and icon vertically */
    }

    .room-registry-dropdown span {
      margin-top: 0;
      /* Ensure no extra top margin is applied */
    }

    .code-books-dropdown {
      color: $colorWhitePrimary;
      padding-top: 6px;
      position: absolute;
      left: 10px;
      display: flex;
      align-items: center;
    }

    .code-books-dropdown .dropdown-content {
      display: flex;
      align-items: center;
      /* Centers the text and icon vertically */
    }

    /* Positioning the dropdown */
    .dropdown-menu {
      /* Hidden by default */
      background-color: $colorBlueTertiary;
      color: #f8f9fa;
      display: block;
    }

    // button:focus .dropdown-menu {
    //   display: block;
    //   /* Show when the button is focused */
    // }

    .dropdown-menu a:hover {
      background-color: white;
      /* Change to your desired hover color */
      color: #495057;
    }

    .dropdown-item {
      color: #f8f9fa;
      border-radius: 5px;
    }

    .dropdown-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px;
    }
  }
}