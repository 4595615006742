.navbar-wrapper {
  background-color: $colorBlueTertiary;
  display: flex;
  height: 50px;
  width: 100%;

  .navbar-content {
    width: 95%;
  }

  .navbar-profile {
    .logout-btn {
      color: $colorWhitePrimary;
      padding-top: 6px;
      padding-left: 30px;
      position: absolute;
      right: 10px;
      .mat-menu,
      .mat-icon {
        outline: none !important;
      }

      .mat-icon {
        font-size: 32px;
      }
    }

    .hamburger-btn {
      color: $colorWhitePrimary;
      padding-top: 6px;
      position: absolute;
      left: -5px;
      .mat-menu,
      .mat-icon {
        outline: none !important;
      }
      .mat-icon {
        font-size: 32px;
      }
    }
  }
}
