.wrapper .sidebar{
  background: #118acb;
  position: fixed;
  top: 0;
  left: 0;
  width: 205px;
  height: 100%;
  padding: 10px 0;
  padding-bottom: 180px;
  transition: all 0.5s ease;
  overflow: hidden;
}

.wrapper .sidebar .profile{
  margin-bottom: 30px;
  text-align: center;
}

.wrapper .sidebar .profile img{
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;

}

.wrapper .sidebar .profile h3{
  color: #ffffff;
  margin: 10px 0 5px;
}

.wrapper .sidebar .profile p{
  color: rgb(206, 240, 253);
  font-size: 14px;
}

.wrapper .sidebar ul li a{
  display: block;
  padding: 13px 30px;
  color: rgb(241, 237, 237);
  font-size: 16px;
  position: relative;
}

.wrapper .sidebar ul li a .icon{
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}

.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active{
  color: #118acb;

  background:white;
  border-right: 2px solid #118acb;
  border-radius: 6px;
}

.wrapper .sidebar ul li a:hover .icon,
.wrapper .sidebar ul li a.active .icon{
  color: #0c7db1;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before{
  display: block;
}

.wrapper .section{
  width: calc(100% - 0px);
  margin-left: 204px;
  transition: all 0.5s ease;
}

.wrapper .section .top_navbar{
  background: #118acb;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
 
}

.wrapper .section .top_navbar .hamburger a{
  font-size: 28px;
  color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover{
  color: #a2ecff;
}

 .wrapper .section .container{
  margin: 30px;
  background: #fff;
  padding: 50px;
  line-height: 28px;
}

body.active .wrapper .sidebar{
  left: -225px;
}

body.active .wrapper .section{
  margin-left: 0;
  width: 100%;
}







.drawer {
  position: fixed;
  height: 100vh;
  min-width: 25%;
  border-right: 1px solid #ccc;
}
.drawer .header {
  position: absolute;
  right: 0;
  justify-content: space-between;
}
.drawer .contents {
  padding: 16px;
}

.main {
  background-color: #fff;
}

.main.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #ccc;
}




