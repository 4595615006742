.overlay{
  background: rgba(0,0,0,0.5); 
  display: block;
  height:100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none !important;
  z-index: 1;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: absolute;
  top: 49%;
  left: 49%;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(172,12,12, 0.2);
  border-right: 1.1em solid rgba(172,12,12, 0.2);
  border-bottom: 1.1em solid rgba(172,12,12, 0.2);
  border-left: 1.1em solid $colorRedPrimary;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
  z-index: 10;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
