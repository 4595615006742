$colorBluePrimary: #0180e0;
$colorBlueSecondary: #004b85;
$colorBlueTertiary: #118acb;

$colorOrangePrimary: #ffbc49;
$colorOrangeSecondary: #ffa200;
$colorOrangeHover: rgba(255, 188, 73, 0.5);

$colorGreenPrimary: #30962c;
$colorGreenSecondary: #277724;

$colorPurplePrimary: #5b3181;
$colorPurpleSecondary: #4a0e80;

$colorWhitePrimary: #ffffff;
$colorBlackPrimary: #000000;

$colorGrayPrimary: #6d6d6d;
$colorGraySecondary: #2d2d2d;

$colorLightGrayPrimary: #f1f1f1;
$colorLightGraySecondary: #e2e2e2;

$colorRedPrimary: #e11a22;
$colorRedSecondary: darkred;
