@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.main-sidebar {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  white-space: nowrap;
}

.main-navbar {
  top: 0;
  left: 0;
}

.main-sidebar {
  left: 0;
  transition: width 0.3s ease-in-out;
}

.right-sidebar {
  right: 0;
  transition: width 0.3s ease-in-out;
}

.main-panel,
.main-navbar {
  position: relative;
  width: calc(100% - 0px);
  float: right;
  transition: width 0.3s ease-in-out;
}

.main-panel-expand,
.main-navbar-expand {
  width: 100%;
}

.main-sidebar-expand {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.main-content {
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

/** Keyframes */
@keyframes slide-from-right {
  100% {
    right: 0;
  }
}

@keyframes slide-to-right {
  100% {
    right: -400px;
  }
}

@keyframes slide-to-left {
  100% {
    width: calc(100% - 600px);
  }
}

@keyframes slide-from-left-to-right {
  100% {
    width: 100%;
  }
}

@keyframes slide-from-right-to-right {
  100% {
    width: 100%;
  }
}
